import { storeData, deleteData, getData } from '@reshima/index-db';

const dbName = 'reshima-database';
const storeName = 'config';
const id = 'lastUserId';

type LastUserId = {
  lastUserId: string;
};

export async function getLastUserId(): Promise<string | undefined> {
  try {
    const data = await getData<LastUserId>({
      dbName,
      storeName,
      id,
    });

    return data?.lastUserId;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function deleteLastUserId() {
  try {
    await deleteData({ dbName, storeName, id });
  } catch (error) {
    console.error(error);
  }
}

export async function setLastUserId({ userId }: { userId: string }) {
  try {
    await storeData({ dbName, storeName, data: { id, lastUserId: userId } });
  } catch (error) {
    console.error(error);
  }
}
