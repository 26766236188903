import { ItemDefinition } from '@azure/cosmos';
import { getItem, upsertItem } from '@reshima/azure-cosmos-db';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { UpdateCategory } from './models';

type ItemsCategoriesUpdateItem = ItemDefinition & {
  count: number;
};

const {
  COSMOS_DB_ENDPOINT: endpoint = '',
  COSMOS_ITEMS_CATEGORIES_DATABASE_ID: databaseId = '',
  COSMOS_ITEMS_CATEGORIES_UPDATES_CONTAINER_ID: containerId = '',
} = process.env;

const delimiter = '|';

export async function upsertItemCategoryUpdate({
  updateCategory: { categoryId, itemName },
}: {
  updateCategory: UpdateCategory;
}) {
  const name = 'UpsertItemCategoryUpdate';
  const action = Action.Upsert;

  const properties = {
    categoryId,
    itemName,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const itemId = [itemName.replace(delimiter, ''), categoryId].join(
      delimiter,
    );

    const existingItem = await getItem<ItemsCategoriesUpdateItem>({
      endpoint,
      databaseId,
      containerId,
      itemId,
      partitionKeyValue: itemId,
    });

    const count = (existingItem?.count || 0) + 1;

    const item: ItemsCategoriesUpdateItem = {
      id: itemId,
      count,
    };

    await upsertItem({
      endpoint,
      databaseId,
      containerId,
      item,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: { ...properties, count },
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
