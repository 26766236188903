import { CosmosClient } from '@azure/cosmos';
import { DefaultAzureCredential } from '@azure/identity';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';

const name = 'CosmosDBClient';

const clients: Record<
  string,
  {
    client: CosmosClient;
    expiration: number;
  }
> = {};

function initializeClient({ endpoint }: { endpoint: string }): CosmosClient {
  const action = Action.Initialize;

  const properties = {
    endpoint,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const credential = new DefaultAzureCredential();
    const client = new CosmosClient({ endpoint, aadCredentials: credential });

    clients[endpoint] = {
      client,
      expiration: Date.now() + 1000 * 60 * 5,
    };

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return client;
  } catch (error) {
    trackException({
      name,
      action,
      properties,
      start,
      error,
    });
    throw error;
  }
}

export function getClient({ endpoint }: { endpoint: string }): CosmosClient {
  const properties = {
    endpoint,
  };

  const currentInstance = clients[endpoint];

  if (!currentInstance) {
    return initializeClient({ endpoint });
  }

  const { client, expiration } = currentInstance;

  if (expiration > Date.now()) {
    return client;
  }

  trackEvent({
    name,
    action: Action.Dispose,
    actionModifier: ActionModifier.End,
    properties,
  });

  client.dispose();

  return initializeClient({ endpoint });
}
