import { CollectionReference, collection } from 'firebase/firestore';
import { FirebaseList, FirebaseUserData } from './models';
import { getFirestoreApp } from './firebase-firestore';

interface AppCollectionsData {
  users: FirebaseUserData;
  lists: FirebaseList;
}

export type AppCollectionsNames = keyof AppCollectionsData;

export const getCollection = <CName extends AppCollectionsNames>(
  collectionName: CName
) =>
  collection(getFirestoreApp(), collectionName) as CollectionReference<
    AppCollectionsData[CName]
  >;
