'use client';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import { ProviderId } from '@reshima/shared';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';

export function SignInButton({
  isSignedId,
  isSigningIn,
  disabled,
  providerId,
  onClick,
}: {
  isSignedId: boolean;
  isSigningIn: boolean;
  disabled: boolean;
  providerId: ProviderId;
  onClick: () => void;
}) {
  const dictionary = useTranslations();
  const signInProviders = dictionary['sign-in-providers'];
  const { signIn, signedIn, signingIn } = dictionary['sign-in-button'];

  const icons: Record<ProviderId, JSX.Element> = {
    google: <FcGoogle className="text-lg" />,
    apple: <FaApple className="text-lg" />,
  };

  function getLabel() {
    if (isSignedId) {
      return signedIn;
    }

    if (isSigningIn) {
      return signingIn;
    }

    return signIn;
  }

  return (
    <Button
      className="flex gap-2 items-center"
      disabled={disabled || isSignedId || isSigningIn}
      onClick={onClick}
      secondary
    >
      {icons[providerId]}
      {[getLabel(), signInProviders[providerId]].join(' ')}
    </Button>
  );
}
