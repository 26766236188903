import { storeData, deleteData, getData } from '@reshima/index-db';

const dbName = 'reshima-database';
const storeName = 'config';
const id = 'lastVisitedList';

type LastVisitedList = {
  lastVisitedList: string;
};

export async function getLastVisitedList(): Promise<string | undefined> {
  try {
    const data = await getData<LastVisitedList>({
      dbName,
      storeName,
      id,
    });

    return data?.lastVisitedList;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function deleteLastVisitedList() {
  try {
    await deleteData({ dbName, storeName, id });
  } catch (error) {
    console.error(error);
  }
}

export async function setLastVisitedList({ listId }: { listId: string }) {
  try {
    await storeData({
      dbName,
      storeName,
      data: { id, lastVisitedList: listId },
    });
  } catch (error) {
    console.error(error);
  }
}
