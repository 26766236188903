import {
  Category,
  CategoryId,
  categoriesMap,
  defaultFixedCategoriesOrderMap,
  itemCategories,
} from '@reshima/category';
import {
  AppUser,
  CategoryItems,
  Item,
  List,
  ListItemsCompletedSortBy,
  ListSortBy,
  ListSortByDirection,
} from './models';

const { Other, Loading } = categoriesMap;

export function isItemChecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !!checked;
}

export function isItemUnchecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !checked;
}

function getCategory({ item }: { item: Item }): Category {
  return (item.categoryId && categoriesMap[item.categoryId]) || Other;
}

export function sortItemsByNameAsc(a: Item, b: Item): number {
  return a.name.localeCompare(b.name);
}

export function sortItemsByNameDesc(a: Item, b: Item): number {
  return b.name.localeCompare(a.name);
}

export function sortItemsByCheckedUpdatedAtDesc(a: Item, b: Item): number {
  return b.checkedUpdatedAt.getTime() - a.checkedUpdatedAt.getTime();
}

export function sortItemsByCheckedUpdatedAtAsc(a: Item, b: Item): number {
  return a.checkedUpdatedAt.getTime() - b.checkedUpdatedAt.getTime();
}

export function sortListsByCreatedAtAsc(a: List, b: List): number {
  return a.createdAt.getTime() - b.createdAt.getTime();
}

function reduceItemsByCategory({
  items,
}: {
  items: Item[];
}): Record<CategoryId, CategoryItems> {
  return items.reduce(
    (acc, item) => {
      const category = getCategory({ item });

      const existing = acc[category.id];
      const categoryItems = [...(existing?.categoryItems || []), item];

      return {
        ...acc,
        [category.id]: {
          category,
          categoryItems,
        },
      };
    },
    {} as Record<CategoryId, CategoryItems>,
  );
}

export function getListManualSortedItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}): Item[] {
  const itemsOrderMap: Record<string, number> = Object.fromEntries(
    (list.itemsOrder || []).map((itemId, index) => [itemId, index]),
  );

  return items.sort((a, b) => {
    if (
      Number.isNaN(Number(itemsOrderMap[a.id])) ||
      Number.isNaN(Number(itemsOrderMap[b.id]))
    ) {
      return sortItemsByCheckedUpdatedAtDesc(a, b);
    }

    return itemsOrderMap[a.id] - itemsOrderMap[b.id];
  });
}

export function getManualSortedLists({
  listsOrder,
  lists,
}: {
  listsOrder: string[];
  lists: List[];
}): List[] {
  const listsOrderMap: Record<string, number> = Object.fromEntries(
    (listsOrder || []).map((listId, index) => [listId, index]),
  );

  return lists.sort((a, b) => {
    if (
      Number.isNaN(Number(listsOrderMap[a.id])) ||
      Number.isNaN(Number(listsOrderMap[b.id]))
    ) {
      return sortListsByCreatedAtAsc(a, b);
    }

    return listsOrderMap[a.id] - listsOrderMap[b.id];
  });
}

export function getSortedItemsByCategory({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): CategoryItems[] {
  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(reduceItemsByCategory({ items })),
  });
}

export function getAllCategoriesItems({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): CategoryItems[] {
  const categoriesItems = reduceItemsByCategory({ items });

  const allCategoriesItems = Object.fromEntries(
    itemCategories.map((category) => [
      category.id,
      { category, categoryItems: [] as Item[] },
    ]),
  ) as Record<CategoryId, CategoryItems>;

  const mergedCategoriesItems = {
    ...allCategoriesItems,
    ...categoriesItems,
  };

  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(mergedCategoriesItems),
  });
}

export function sortCategoriesByOrder({
  a,
  b,
  categoriesOrderMap,
}: {
  a: CategoryId;
  b: CategoryId;
  categoriesOrderMap: Record<CategoryId, number>;
}): number {
  if (a === Loading.id) {
    return -1;
  }

  if (b === Loading.id) {
    return 1;
  }

  if (a in categoriesOrderMap && b in categoriesOrderMap) {
    return categoriesOrderMap[a] - categoriesOrderMap[b];
  }

  if (a in categoriesOrderMap) {
    return -1;
  }

  if (b in categoriesOrderMap) {
    return 1;
  }

  if (
    a in defaultFixedCategoriesOrderMap &&
    b in defaultFixedCategoriesOrderMap
  ) {
    return (
      defaultFixedCategoriesOrderMap[a] - defaultFixedCategoriesOrderMap[b]
    );
  }

  if (a in defaultFixedCategoriesOrderMap) {
    return -1;
  }

  if (b in defaultFixedCategoriesOrderMap) {
    return 1;
  }

  return a.localeCompare(b);
}

function sortCategoriesItemsByOrder({
  categoriesOrder = [],
  categoriesItems,
}: {
  categoriesOrder?: CategoryId[];
  categoriesItems: CategoryItems[];
}): CategoryItems[] {
  const categoriesOrderMap = Object.fromEntries(
    categoriesOrder.map((category, index) => [category, index]),
  ) as Record<CategoryId, number>;

  return categoriesItems.sort((a, b) =>
    sortCategoriesByOrder({
      a: a.category.id,
      b: b.category.id,
      categoriesOrderMap,
    }),
  );
}

export function getSortedItems({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): Item[] {
  const categoriesItems = getSortedItemsByCategory({
    categoriesOrder,
    items,
  });

  return categoriesItems
    .map(({ categoryItems }) =>
      categoryItems.sort(sortItemsByCheckedUpdatedAtDesc),
    )
    .flat();
}

export function isListAdmin({
  list: { admins },
  user: {
    firebaseUser: { uid },
  },
}: {
  list: List;
  user: AppUser;
}): boolean {
  return admins.includes(uid);
}

export function isListSortBy(sortBy?: unknown): sortBy is ListSortBy {
  return Object.values(ListSortBy).includes(sortBy as ListSortBy);
}

export function isListItemsCompletedSortBy(
  listItemsCompletedSortBy?: unknown,
): listItemsCompletedSortBy is ListItemsCompletedSortBy {
  return Object.values(ListItemsCompletedSortBy).includes(
    listItemsCompletedSortBy as ListItemsCompletedSortBy,
  );
}

export function isListSortByDirection(
  sortByDirection?: unknown,
): sortByDirection is ListSortByDirection {
  return Object.values(ListSortByDirection).includes(
    sortByDirection as ListSortByDirection,
  );
}
