'use client';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useListenForSwiftAuth } from '@reshima/ios-ui';
import {
  AppUser,
  anonymouslySignIn,
  signIn,
  getAuthApp,
  parseUser,
  signOut,
  UserData,
  getUserDataStream,
  getPreAuthUserCachedData,
} from '@reshima/firebase';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { useMigrateAnonymousUserData } from './useMigrateAnonymousUserData';
import {
  deleteLastUserId,
  setLastUserId,
} from '@reshima/user-local-persistence';

export type ClientAuthContext = {
  userLoading: boolean;
  user?: AppUser;
  userData?: UserData;
  userDataLoading: boolean;
  anonymouslySignIn: typeof anonymouslySignIn;
  signIn: typeof signIn;
  signOut: typeof signOut;
};

export const clientAuthContext = createContext<ClientAuthContext>({
  userLoading: true,
  userDataLoading: true,
  signIn: () => Promise.resolve(),
  anonymouslySignIn: () => Promise.resolve(null as unknown as AppUser),
  signOut: () => Promise.resolve(),
});

export function useClientAuth(): ClientAuthContext {
  return useContext(clientAuthContext);
}

export function ClientAuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const name = 'ClientAuthProvider';
  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState<AppUser>();
  const [userData, setUserData] = useState<UserData | undefined>(
    getPreAuthUserCachedData().userData,
  );
  const [userDataLoading, setUserDataLoading] = useState(true);

  useMigrateAnonymousUserData({ fromUser: user });
  useListenForSwiftAuth();

  const listenToOnAuthStateChanged = useCallback(() => {
    const action = 'OnAuthStateChanged';

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    return onAuthStateChanged(
      getAuthApp(),
      async (user) => {
        try {
          setUserLoading(false);

          if (!user) {
            setUser(undefined);

            deleteLastUserId();

            trackEvent({
              name,
              action,
              actionModifier: ActionModifier.End,
              start,
              properties: {
                user: !!user,
              },
            });

            return;
          }

          setLastUserId({ userId: user.uid });

          const parsedUser = parseUser(user);

          setUser(parsedUser);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
            properties: {
              user: !!user,
              providers: parsedUser.providers,
            },
          });
        } catch (error) {
          trackException({
            name,
            action,
            error,
            start,
          });
        }
      },
      (error) => {
        trackException({
          name,
          action,
          error,
        });
      },
    );
  }, []);

  const listenToUserDataStream = useCallback((user: AppUser) => {
    const action = 'GetUserDataStream';

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    return getUserDataStream({
      userId: user.firebaseUser.uid,
      onUserUpdate: (userData) => {
        setUserData(userData);
        setUserDataLoading(false);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
        });
      },
      onError: (error) => {
        setUserDataLoading(false);
        trackException({
          name,
          action,
          start,
          error,
        });
      },
    });
  }, []);

  useEffect(() => listenToOnAuthStateChanged(), [listenToOnAuthStateChanged]);
  useEffect(() => {
    if (userLoading) {
      return;
    }

    if (!user) {
      setUserData(undefined);
      return;
    }

    return listenToUserDataStream(user);
  }, [userLoading, user, listenToUserDataStream]);

  return (
    <clientAuthContext.Provider
      value={{
        user,
        userLoading,
        userData,
        userDataLoading,
        anonymouslySignIn,
        signIn,
        signOut,
      }}
    >
      {children}
    </clientAuthContext.Provider>
  );
}
