import {
  query,
  where,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
  Unsubscribe,
  FirestoreError,
  or,
  Query,
  deleteDoc,
  getDocFromCache,
  QueryFieldFilterConstraint,
  getDocsFromServer,
  deleteField,
} from 'firebase/firestore';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { CategoryId } from '@reshima/category';
import {
  FirebaseList,
  List,
  ListItemsCompletedSortBy,
  ListSortBy,
  ListSortByDirection,
  UpdateAbleList,
} from '../models';
import { getCollection } from '../collections';
import { createList } from '../callable-functions';
import {
  isListItemsCompletedSortBy,
  isListSortBy,
  isListSortByDirection,
} from '../utils';

const name = 'FirebaseLists';

const getListsCollection = () => getCollection('lists');

export const defaultListSortByDirection = ListSortByDirection.asc;

function parse({ id, list }: { id: string; list?: FirebaseList }): List {
  const clientCreatedAt = list?.clientCreatedAt?.toDate() || new Date();
  return {
    ...list,
    id,
    admins: list?.admins || [],
    sharedWith: list?.sharedWith || [],
    clientCreatedAt,
    createdAt: list?.createdAt?.toDate() || clientCreatedAt,
    updatedAt: list?.updatedAt?.toDate() || clientCreatedAt,
    sortBy: isListSortBy(list?.sortBy)
      ? list.sortBy
      : ListSortBy.fixedCategories,
    sortByDirection: isListSortByDirection(list?.sortByDirection)
      ? list.sortByDirection
      : defaultListSortByDirection,
    completedItemsSortBy: isListItemsCompletedSortBy(list?.completedItemsSortBy)
      ? list?.completedItemsSortBy
      : ListItemsCompletedSortBy.lastCheckedItem,
    completedItemsSortByDirection: isListSortByDirection(
      list?.completedItemsSortByDirection,
    )
      ? list.completedItemsSortByDirection
      : defaultListSortByDirection,
    disableActivity: list?.disableActivity || false,
  };
}

function ownedQuery({
  userId,
}: {
  userId: string;
}): QueryFieldFilterConstraint {
  return where('admins', 'array-contains', userId);
}

function sharedQuery({
  userId,
}: {
  userId: string;
}): QueryFieldFilterConstraint {
  return where('sharedWith', 'array-contains', userId);
}

function listsQuery({ userId }: { userId: string }): Query<FirebaseList> {
  return query(
    getListsCollection(),
    or(ownedQuery({ userId }), sharedQuery({ userId })),
  );
}

async function updateList(list: UpdateAbleList): Promise<void> {
  await updateDoc(doc(getListsCollection(), list.id), {
    ...list,
    updatedAt: serverTimestamp(),
  });
}

export async function deleteList({ id }: List): Promise<void> {
  const action = 'DeleteList';

  const properties = {
    listId: id,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await deleteDoc(doc(getListsCollection(), id));

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function getListFromCache({
  listId,
}: {
  listId: string;
}): Promise<List | undefined> {
  const action = 'GetListFromCache';

  const properties = {
    listId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const docSnapshot = await getDocFromCache(
      doc(getListsCollection(), listId),
    );

    if (!docSnapshot.exists()) {
      return undefined;
    }

    const list = parse({
      id: docSnapshot.id,
      list: docSnapshot.data(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return list;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export function getListStream({
  listId,
  onListUpdate,
  onError,
}: {
  listId: string;
  onListUpdate: (list?: List) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    doc(getListsCollection(), listId),
    (snapshot) => {
      const list: List = parse({
        id: snapshot.id,
        list: snapshot.data(),
      });

      onListUpdate(list);
    },
    onError,
  );
}

export function getListsStream({
  userId,
  onUpdate,
  onError,
}: {
  userId: string;
  onUpdate: (lists: List[]) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    listsQuery({ userId }),
    ({ docs }) => {
      const lists = docs.map((doc) =>
        parse({
          id: doc.id,
          list: doc.data(),
        }),
      );
      onUpdate(lists);
    },
    onError,
  );
}

export async function getListsFromServer({
  userId,
}: {
  userId: string;
}): Promise<List[]> {
  const action = 'GetLists';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const querySnapshot = await getDocsFromServer(listsQuery({ userId }));

    const lists = querySnapshot.docs.map((doc) =>
      parse({
        id: doc.id,
        list: doc.data(),
      }),
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: {
        listsCount: lists.length,
      },
    });

    return lists;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}

export async function upsertLists({
  userId,
}: {
  userId: string;
}): Promise<List[]> {
  const userLists = await getListsFromServer({ userId });
  if (!userLists.length) {
    const list = await createList();
    return [list];
  } else {
    return userLists;
  }
}

export async function updateListFixedCategoriesOrder({
  listId,
  fixedCategoriesOrder,
}: {
  listId: string;
  fixedCategoriesOrder?: CategoryId[];
}): Promise<void> {
  const action = 'UpdateListFixedCategoriesOrder';

  const properties = {
    listId,
    fixedCategoriesOrder,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      fixedCategoriesOrder: fixedCategoriesOrder || deleteField(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateListDisableActivity({
  listId,
  disableActivity,
}: {
  listId: string;
  disableActivity: boolean;
}): Promise<void> {
  const action = 'UpdateListDisableActivity';

  const properties = {
    listId,
    disableActivity,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      disableActivity,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateListName({
  listId,
  newName,
}: {
  listId: string;
  newName: string;
}): Promise<void> {
  const action = 'UpdateListName';

  const properties = {
    listId,
    newNameLength: newName?.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      name: newName,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListSortBy({
  listId,
  sortBy,
}: {
  listId: string;
  sortBy: ListSortBy;
}): Promise<void> {
  const action = 'UpdateListSortBy';

  const properties = {
    listId,
    sortBy,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      sortBy,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListCompletedItemsSortBy({
  listId,
  completedItemsSortBy,
}: {
  listId: string;
  completedItemsSortBy: ListItemsCompletedSortBy;
}): Promise<void> {
  const action = 'UpdateListCompletedItemsSortBy';

  const properties = {
    listId,
    completedItemsSortBy,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      completedItemsSortBy,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListCompletedItemsSortByDirection({
  listId,
  completedItemsSortByDirection,
}: {
  listId: string;
  completedItemsSortByDirection: ListSortByDirection;
}): Promise<void> {
  const action = 'UpdateListCompletedItemsSortByDirection';

  const properties = {
    listId,
    completedItemsSortByDirection,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      completedItemsSortByDirection,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListSortByDirection({
  listId,
  sortByDirection,
}: {
  listId: string;
  sortByDirection: ListSortByDirection;
}): Promise<void> {
  const action = 'UpdateListSortByDirection';

  const properties = {
    listId,
    sortByDirection,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      sortByDirection,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListItemsOrder({
  listId,
  itemsOrder,
}: {
  listId: string;
  itemsOrder: string[];
}): Promise<void> {
  const action = 'UpdateListItemsOrder';

  const properties = {
    listId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      itemsOrder,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}
