type Item = { id?: string };

const escapingCharacters: Record<string, string> = {
  '/': '%2F',
  '?': '%3F',
  '#': '%23',
};

const unEscapingCharacters: Record<string, string> = Object.entries(
  escapingCharacters,
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

const escapingCharactersRegex = new RegExp(
  `[${Object.keys(escapingCharacters).join('')}]`,
  'g',
);

const unEscapingCharactersRegex = new RegExp(
  `(${Object.keys(unEscapingCharacters).join('|')})`,
  'g',
);

export function escapeSpecialCharacters(value: string): string {
  return value.replace(
    escapingCharactersRegex,
    (match) => escapingCharacters[match],
  );
}

export function unEscapeSpecialCharacters(value: string): string {
  return value.replace(
    unEscapingCharactersRegex,
    (match) => unEscapingCharacters[match],
  );
}

export function escapeItem<T extends Item>(item: T): T {
  return {
    ...item,
    ...(item.id ? { id: escapeSpecialCharacters(item.id) } : {}),
  };
}

export function unEscapeItem<T extends Item>(item: T): T {
  return {
    ...item,
    ...(item.id ? { id: unEscapeSpecialCharacters(item.id) } : {}),
  };
}
