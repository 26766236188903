'use client';
import { useTranslations } from '@reshima/translations-ui';
import { ReshimaPage } from '@reshima/shared-ui';
import { SignIn, useClientAuth } from '@reshima/client-auth-ui';
import { useNavigate } from '@reshima/navigation-ui';

export function SignInPage() {
  const { heading, description } = useTranslations()['sign-in'];

  const navigate = useNavigate();
  const { user } = useClientAuth();

  if (user?.isSignedIn) {
    navigate('/lists');
  }

  return (
    <ReshimaPage name="SignInPage" heading={heading} description={description}>
      <div className="flex justify-center">
        <SignIn />
      </div>
    </ReshimaPage>
  );
}
