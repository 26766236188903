import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { httpsCallable } from 'firebase/functions';
import { getCallable } from '../firebase-callable';

const name = 'LeaveList';

export async function leaveList({ listId }: { listId: string }) {
  const action = Action.Callable;

  const properties = {
    listId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const leaveListCallable = httpsCallable(getCallable(), 'leaveList');

    await leaveListCallable({ listId });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
