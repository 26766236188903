import { Database } from '@azure/cosmos';
import { getClient } from './client';

export async function createDatabase({
  endpoint,
  id,
  maxThroughput,
}: {
  endpoint: string;
  id: string;
  maxThroughput: number;
}): Promise<Database> {
  try {
    console.log(`Creating database ${id}...`);

    const { database } = await getClient({
      endpoint,
    }).databases.createIfNotExists({
      id,
      maxThroughput,
    });

    console.log(`Database ${id} created successfully`);
    return database;
  } catch (error) {
    console.error('Error creating database:', error);
    throw error;
  }
}

export async function getDatabase({
  endpoint,
  id,
}: {
  endpoint: string;
  id: string;
}): Promise<Database> {
  try {
    console.log(`Getting database ${id}...`);

    const database = getClient({ endpoint }).database(id);

    console.log(`Database ${id} retrieved successfully`);
    return database;
  } catch (error) {
    console.error('Error getting database:', error);
    throw error;
  }
}
