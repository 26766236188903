import { Dictionary } from '@reshima/translations';
import { ReshimaPage } from '@reshima/shared-ui';
import { SignOutButton } from '@reshima/client-auth-ui';

export function SignOutPage({ dictionary }: { dictionary: Dictionary }) {
  const { heading, description } = dictionary['sign-out'];

  return (
    <ReshimaPage name="SignOutPage" heading={heading} description={description}>
      <div className="flex justify-center">
        <SignOutButton />
      </div>
    </ReshimaPage>
  );
}
