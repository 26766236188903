import { Container, Database, PartitionKeyDefinition } from '@azure/cosmos';
import { getClient } from './client';

export async function createContainer({
  database,
  containerId,
  partitionKey,
}: {
  database: Database;
  containerId: string;
  partitionKey: PartitionKeyDefinition;
}): Promise<Container> {
  try {
    const containerOptions = {
      id: containerId,
      partitionKey,
    };

    const { container } =
      await database.containers.createIfNotExists(containerOptions);

    console.log(
      `Container ${containerId} created successfully with partition key ${partitionKey.paths}`,
    );
    return container;
  } catch (error) {
    console.error('Error creating container:', error);
    throw error;
  }
}

export function getContainer({
  endpoint,
  databaseId,
  containerId,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
}) {
  return getClient({ endpoint }).database(databaseId).container(containerId);
}
