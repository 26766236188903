import { FirebaseApp } from 'firebase/app';
import {
  Firestore,
  initializeFirestore,
  persistentLocalCache,
} from 'firebase/firestore';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

const name = 'FirebaseFirestore';

let fireStoreApp: Firestore;

export function initFirestore(app: FirebaseApp): void {
  const action = 'Initialize';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    fireStoreApp = initializeFirestore(app, {
      localCache: persistentLocalCache(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: { fireStoreApp: fireStoreApp.toJSON() },
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }
}

export function getFirestoreApp() {
  return fireStoreApp;
}
