import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { httpsCallable } from 'firebase/functions';
import { getCallable } from '../firebase-callable';

const name = 'DeleteUser';

export async function deleteUser() {
  const action = Action.Callable;

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const deleteUserCallable = httpsCallable(getCallable(), 'deleteUser');

    await deleteUserCallable();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}
