import { isCategoryValid } from '@reshima/category';
import { UpdateCategory } from './models';

export function isUpdateCategory(body: UpdateCategory): body is UpdateCategory {
  if (!body) {
    return false;
  }

  const { itemName, categoryId } = body;

  return (
    typeof itemName === 'string' &&
    itemName.length > 0 &&
    isCategoryValid(categoryId)
  );
}
