'use client';
import { useCallback, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ProviderId } from '@reshima/shared';
import { SignInButton } from './sign-in-button';
import { useClientAuth } from './client-auth';

export function SignIn() {
  const name = 'SignIn';

  const [signingInProviderId, setSigningInProviderId] = useState<ProviderId>();
  const { user, userLoading, signIn } = useClientAuth();

  const onClick = useCallback(
    async ({ providerId }: { providerId: ProviderId }) => {
      const action = Action.Click;

      setSigningInProviderId(providerId);

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      try {
        await signIn({ providerId });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
        });
      } catch (error) {
        // TODO: Handle error experience

        trackException({
          name,
          action,
          start,
          error,
        });
      }

      setSigningInProviderId(undefined);
    },
    [signIn],
  );

  if (userLoading || user?.isSignedIn) {
    return <AiOutlineLoading className="text-3xl animate-spin" />;
  }

  return (
    <div className="flex flex-col gap-2">
      {Object.values(ProviderId).map((providerId) => (
        <SignInButton
          key={providerId}
          isSignedId={!!user?.providers[providerId]}
          isSigningIn={signingInProviderId === providerId}
          disabled={!!signingInProviderId}
          providerId={providerId}
          onClick={() => onClick({ providerId })}
        />
      ))}
    </div>
  );
}
