export enum Targets {
  popup = 'popup',
  background = 'background',
  offscreen = 'offscreen',
  iframe = 'iframe',
}

export enum Types {
  readyRequest = 'readyRequest',
  readyResponse = 'readyResponse',
  userCredentialRequest = 'userCredentialRequest',
  userCredentialResponse = 'userCredentialResponse',
}

export type ErrorMessage = {
  error: unknown;
};

export type Message =
  | ErrorMessage
  | {
      target: Targets;
      type: Types;
    };

export function isErrorMessage(message: unknown): message is ErrorMessage {
  return (message as ErrorMessage)?.error !== undefined;
}
