import { getCountFromServer, query, where } from 'firebase/firestore';
import { isItemChecked, isItemUnchecked } from '../utils';
import { getItemsFromCache, getListItemsCollection } from './items';

async function getItemsCountFromServer({
  listId,
  checked,
}: {
  listId: string;
  checked: boolean;
}): Promise<number> {
  const q = query(
    getListItemsCollection({ listId }),
    where('checked', '==', checked || undefined)
  );
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
}

async function getItemsCountFromCache({
  listId,
  checked,
}: {
  listId: string;
  checked: boolean;
}): Promise<number> {
  const items = await getItemsFromCache({ listId });
  return items.filter(checked ? isItemChecked : isItemUnchecked).length;
}

export async function getUncheckedItemsCount({
  listId,
}: {
  listId: string;
}): Promise<number> {
  const checked = false;
  try {
    return await getItemsCountFromServer({ listId, checked });
  } catch (error) {
    return await getItemsCountFromCache({ listId, checked });
  }
}

export async function getCheckedItemsCount({
  listId,
}: {
  listId: string;
}): Promise<number> {
  const checked = true;
  try {
    return await getItemsCountFromCache({ listId, checked });
  } catch (error) {
    return await getItemsCountFromServer({ listId, checked });
  }
}
