import { FeedOptions, FeedResponse, SqlQuerySpec } from '@azure/cosmos';
import { getContainer } from './container';
import { unEscapeItem } from './escaping';

type Response<T> = Pick<FeedResponse<T>, 'continuationToken' | 'resources'>;

export async function queryItems<T>({
  endpoint,
  databaseId,
  containerId,
  querySpec,
  feedOptions,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  querySpec: SqlQuerySpec;
  feedOptions: FeedOptions;
}): Promise<Response<T>> {
  const container = getContainer({ endpoint, databaseId, containerId });
  const queryIterator = container.items.query(querySpec, feedOptions);
  const { continuationToken, resources } = await queryIterator.fetchNext();
  const unEscapeResources = resources.map(unEscapeItem);
  return { continuationToken, resources: unEscapeResources };
}

export async function queryItemsAll<T>({
  endpoint,
  databaseId,
  containerId,
  querySpec,
  feedOptions,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  querySpec: SqlQuerySpec;
  feedOptions: FeedOptions;
}): Promise<FeedResponse<T>> {
  const container = getContainer({ endpoint, databaseId, containerId });
  const queryIterator = container.items.query(querySpec, {
    ...feedOptions,
    maxItemCount: -1,
  });
  return queryIterator.fetchAll();
}
