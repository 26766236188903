'use client';
import { useCallback, useEffect } from 'react';
import { ProviderId } from '@reshima/shared';
import {
  createOffscreenReadyResponseMessage,
  createOffscreenUserCredentialResponseMessage,
  ErrorMessage,
  isIframeReadyRequestMessage,
  isIframeUserCredentialRequestMessage,
  Message,
  OffscreenReadyResponseMessage,
  OffscreenUserCredentialResponseMessage,
} from '@reshima/messages';
import { signInWithPopupProvider } from '@reshima/firebase';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

export function ExtensionSignInPage() {
  const name = 'ExtensionSignInPage';

  const sendResponse = useCallback(
    (
      message:
        | ErrorMessage
        | OffscreenReadyResponseMessage
        | OffscreenUserCredentialResponseMessage
    ) => {
      const parentFrame = document.location.ancestorOrigins[0];
      globalThis.parent.self.postMessage(JSON.stringify(message), parentFrame);
    },
    []
  );

  const ready = useCallback(() => {
    const action = 'Ready';

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      sendResponse(createOffscreenReadyResponseMessage());

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
      sendResponse({ error });
    }
  }, [sendResponse]);

  const startSignIn = useCallback(
    async ({ providerId }: { providerId: ProviderId }) => {
      const action = 'SignIn';

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      try {
        const userCredential = await signInWithPopupProvider({ providerId });

        sendResponse(
          createOffscreenUserCredentialResponseMessage({
            userCredential,
          })
        );

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
        });
        sendResponse({ error });
      }
    },
    [sendResponse]
  );

  useEffect(() => {
    async function onMessage({ data }: MessageEvent<Message>) {
      if (isIframeReadyRequestMessage(data)) {
        return ready();
      }

      if (isIframeUserCredentialRequestMessage(data)) {
        return startSignIn({
          providerId: data.providerId,
        });
      }
    }

    globalThis.addEventListener('message', onMessage);

    return () => {
      globalThis.removeEventListener('message', onMessage);
    };
  }, [startSignIn, ready]);

  return null;
}
