import { Message, Targets, Types } from './messages';
import {
  BackgroundRecipientMessage,
  OffscreenRecipientMessage,
  isBackgroundRecipientMessage,
  isOffscreenRecipientMessage,
} from './recipients';

type ReadyResponseMessage = Message & {
  type: Types.readyResponse;
};

export type OffscreenReadyResponseMessage = OffscreenRecipientMessage &
  ReadyResponseMessage;

export type BackgroundReadyResponseMessage = BackgroundRecipientMessage &
  ReadyResponseMessage;

function isReadyResponseMessage(
  message: unknown
): message is ReadyResponseMessage {
  return (message as ReadyResponseMessage)?.type === Types.readyResponse;
}

export function isOffscreenReadyResponseMessage(
  message: unknown
): message is OffscreenReadyResponseMessage {
  return (
    isOffscreenRecipientMessage(message) && isReadyResponseMessage(message)
  );
}

export function isBackgroundReadyResponseMessage(
  message: unknown
): message is BackgroundReadyResponseMessage {
  return (
    isBackgroundRecipientMessage(message) && isReadyResponseMessage(message)
  );
}

export function createOffscreenReadyResponseMessage(): OffscreenReadyResponseMessage {
  return {
    target: Targets.offscreen,
    type: Types.readyResponse,
  };
}

export function createBackgroundReadyResponseMessage(): BackgroundReadyResponseMessage {
  return {
    target: Targets.background,
    type: Types.readyResponse,
  };
}
