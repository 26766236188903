import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { httpsCallable } from 'firebase/functions';
import { getCallable } from '../firebase-callable';

const name = 'JoinList';

export async function joinList({
  shareLink,
}: {
  shareLink: string;
}): Promise<{ id: string }> {
  const action = Action.Callable;

  const properties = {
    shareLink,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const joinListCallable = httpsCallable<
      { shareLink: string },
      { id: string }
    >(getCallable(), 'joinList');

    const {
      data: { id },
    } = await joinListCallable({ shareLink });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: {
        ...properties,
        listId: id,
      },
      start,
    });

    return { id };
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
