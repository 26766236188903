import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { getCategory, suggestCategory } from '@reshima/category-search';
import { logUpdateCategory } from '@reshima/items-categories-updates';
import { CategoryId, categoriesMap } from '@reshima/category';
import { Item, List } from '../models';
import { getAuthApp } from '../firebase-auth';
import { updateItemCategory } from './items';

const name = 'FirebaseItemsCategory';

export async function categoryItem({
  list,
  itemId,
  itemName,
}: {
  list: List;
  itemId: string;
  itemName: string;
}): Promise<void> {
  const action = 'CategoryItem';

  const properties = {
    listId: list.id,
    itemId,
    name,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const categoryId = await getCategorizedItem({ itemName });

    await updateItemCategory({
      list,
      itemId,
      categoryId,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

async function logItemCategoryUpdate({
  item,
  list,
  categoryId,
}: {
  item: Item;
  list: List;
  categoryId: CategoryId;
}): Promise<void> {
  const action = 'LogItemCategoryUpdate';

  const properties = {
    listId: list.id,
    itemId: item.id,
    categoryId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const token = await getAuthApp().currentUser?.getIdToken();

    if (!token) {
      throw new Error('No token');
    }

    if (!item.name) {
      throw new Error('No item name');
    }

    await logUpdateCategory({
      updateCategory: {
        itemName: item.name,
        categoryId,
      },
      token,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
  }
}

export async function manualUpdateCategorizedItem({
  list,
  item,
  categoryId,
}: {
  list: List;
  item: Item;
  categoryId: CategoryId;
}): Promise<void> {
  logItemCategoryUpdate({ item, list, categoryId });

  await updateItemCategory({
    list,
    itemId: item.id,
    categoryId,
  });
}

export async function getCategorizedItem({
  itemName,
}: {
  itemName?: string;
}): Promise<CategoryId> {
  if (!itemName) {
    return categoriesMap.Other.id;
  }

  const token = await getAuthApp().currentUser?.getIdToken();

  if (!token) {
    throw new Error('No token');
  }

  const results = await getCategory({ search: itemName, token });

  return results?.bestMatch?.id || categoriesMap.Other.id;
}

export async function suggestCategorizedItem({
  itemName,
}: {
  itemName?: string;
}): Promise<CategoryId> {
  if (!itemName) {
    return categoriesMap.Other.id;
  }

  const token = await getAuthApp().currentUser?.getIdToken();

  if (!token) {
    throw new Error('No token');
  }

  const results = await suggestCategory({ search: itemName, token });

  return results?.bestMatch?.id || categoriesMap.Other.id;
}
