'use client';
import { useCallback, useEffect } from 'react';
import { ProviderId } from '@reshima/shared';
import { ActionModifier, trackEvent } from '@reshima/telemetry';
import { isIosAuth } from '@reshima/ios';
import { signInWithCredential } from '@reshima/firebase';

const event = 'ios-auth';

export function useListenForSwiftAuth() {
  const name = 'UseListenForSwiftAuth';

  const listenForSwiftAuth = useCallback(() => {
    const action = 'ListenForSwiftAuth';

    if (!isIosAuth()) {
      return;
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    const handleSwiftAuth = (
      event: CustomEvent<{
        idToken: string;
        accessToken: string;
        email: string;
        providerId: ProviderId;
      }>,
    ) => {
      signInWithCredential(event.detail);
    };

    window.addEventListener(event, handleSwiftAuth as EventListener);

    return () =>
      window.removeEventListener(event, handleSwiftAuth as EventListener);
  }, []);

  useEffect(() => listenForSwiftAuth(), [listenForSwiftAuth]);
}
