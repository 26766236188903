import { useCallback, useEffect } from 'react';
import { beforeAuthStateChanged } from 'firebase/auth';
import { AppUser, getAuthApp, migrateLists } from '@reshima/firebase';
import { trackEvent, ActionModifier, trackException } from '@reshima/telemetry';

export function useMigrateAnonymousUserData({
  fromUser,
}: {
  fromUser?: AppUser;
}) {
  const name = 'MigrateAnonymousUserData';

  const setBeforeAuthStateChanged = useCallback(
    ({ fromUser }: { fromUser: AppUser }) => {
      const action = 'BeforeAuthStateChanged';

      return beforeAuthStateChanged(getAuthApp(), async (toUser) => {
        const properties = {
          fromUser: fromUser.firebaseUser.uid,
          toUser: toUser?.uid,
        };

        if (!toUser?.uid) {
          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.Skip,
            properties,
          });

          return;
        }

        const start = trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Start,
          properties,
        });

        try {
          await migrateLists({ toUserId: toUser.uid });

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
            properties,
          });
        } catch (error) {
          trackException({
            name,
            action,
            error,
            start,
            properties,
          });
          throw error;
        }
      });
    },
    [],
  );

  useEffect(() => {
    if (!fromUser) return;
    return setBeforeAuthStateChanged({ fromUser });
  }, [fromUser, setBeforeAuthStateChanged]);
}
