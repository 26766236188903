import { backendPrefix } from './constants';

type Config = {
  backendHost: string;
};

let config: Config;

export function initialize(initConfig: Config) {
  config = {
    ...initConfig,
  };
}

export function getBackendURL() {
  return `${config.backendHost}${backendPrefix}`;
}
