import { ContainerResponse } from '@azure/cosmos';
import { getContainer } from './container';

export async function deleteAllItems({
  endpoint,
  databaseId,
  containerId,
  partitionKey,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  partitionKey: string;
}): Promise<ContainerResponse> {
  const container = getContainer({ endpoint, databaseId, containerId });
  return container.deleteAllItemsForPartitionKey(partitionKey);
}
