type Data = {
  id: string;
} & Record<string, unknown>;

function deleteDatabase({ dbName }: { dbName: string }): Promise<void> {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(dbName);
    deleteRequest.onsuccess = () => resolve();
    deleteRequest.onerror = () => reject(deleteRequest.error);
  });
}

function openDatabase({
  dbName,
  storeName,
}: {
  dbName: string;
  storeName: string;
}): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };

    request.onerror = () => reject(request.error);

    request.onsuccess = async () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        try {
          await deleteDatabase({ dbName });
          resolve(await openDatabase({ dbName, storeName }));
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(db);
      }
    };
  });
}

export async function storeData({
  dbName,
  storeName,
  data,
}: {
  dbName: string;
  storeName: string;
  data: Data;
}): Promise<void> {
  const db = await openDatabase({ dbName, storeName });
  return await new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.put(data);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();
  });
}

export async function deleteData({
  dbName,
  storeName,
  id,
}: {
  dbName: string;
  storeName: string;
  id: string;
}): Promise<void> {
  const db = await openDatabase({ dbName, storeName });
  return await new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.delete(id);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();
  });
}

export async function getData<T>({
  dbName,
  storeName,
  id,
}: {
  dbName: string;
  storeName: string;
  id: string;
}): Promise<T> {
  const db = await openDatabase({ dbName, storeName });
  return await new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(id);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
  });
}
