import { ProviderId } from '@reshima/shared';
import { Message, Targets, Types } from './messages';
import {
  BackgroundRecipientMessage,
  IframeRecipientMessage,
  OffscreenRecipientMessage,
  isBackgroundRecipientMessage,
  isIframeRecipientMessage,
  isOffscreenRecipientMessage,
} from './recipients';

type UserCredentialRequestMessage = Message & {
  type: Types.userCredentialRequest;
  providerId: ProviderId;
};

type BackgroundUserCredentialRequestMessage = BackgroundRecipientMessage &
  UserCredentialRequestMessage;

type OffscreenUserCredentialRequestMessage = OffscreenRecipientMessage &
  UserCredentialRequestMessage;

export type IframeUserCredentialRequestMessage = IframeRecipientMessage &
  UserCredentialRequestMessage;

function isUserCredentialRequestMessage(
  message: unknown
): message is UserCredentialRequestMessage {
  return (
    (message as UserCredentialRequestMessage)?.type ===
    Types.userCredentialRequest
  );
}

export function isBackgroundUserCredentialRequestMessage(
  message: unknown
): message is BackgroundUserCredentialRequestMessage {
  return (
    isBackgroundRecipientMessage(message) &&
    isUserCredentialRequestMessage(message)
  );
}

export function isOffscreenUserCredentialRequestMessage(
  message: unknown
): message is OffscreenUserCredentialRequestMessage {
  return (
    isOffscreenRecipientMessage(message) &&
    isUserCredentialRequestMessage(message)
  );
}

export function isIframeUserCredentialRequestMessage(
  message: unknown
): message is IframeUserCredentialRequestMessage {
  return (
    isIframeRecipientMessage(message) && isUserCredentialRequestMessage(message)
  );
}

export function createBackgroundUserCredentialRequestMessage({
  providerId,
}: {
  providerId: ProviderId;
}): BackgroundUserCredentialRequestMessage {
  return {
    providerId,
    target: Targets.background,
    type: Types.userCredentialRequest,
  };
}

export function createOffscreenUserCredentialRequestMessage({
  providerId,
}: {
  providerId: ProviderId;
}): OffscreenUserCredentialRequestMessage {
  return {
    providerId,
    target: Targets.offscreen,
    type: Types.userCredentialRequest,
  };
}

export function createIframeUserCredentialRequestMessage({
  providerId,
}: {
  providerId: ProviderId;
}): IframeUserCredentialRequestMessage {
  return {
    providerId,
    target: Targets.iframe,
    type: Types.userCredentialRequest,
  };
}
